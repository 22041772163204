import {ValidationResult} from "../common/ui/validation/ProductValidation";
import {SpatialModel} from "../model";
import {ServiceType} from "../services/model";

export enum ProductType {
  RASTER = "Raster",
  VECTOR = "Vector",
  MESH = "Mesh",
  POINT_CLOUD = "Point Cloud",
  MIXED = "Mixed",
  UNKNOWN = "Uknown",
  EMPTY = "Empty",
}

export interface PendingProductForService extends Product {
  pendingServiceType?: ServiceType;
}

export interface Product extends SpatialModel {
  type: ProductType;
  contents: StyledData[];
  creationTime?: string;
  createdBy?: {
    username: string;
  };
  validationByServiceType?: { [serviceType: string]: ValidationResult };
}

export interface StyledData {
  // We don't get ID from the API, but the ReorderableList component requires each potential element to have an ID
  // property. Every API method that returns StyledData will set the ID property to the data property (which is data ID)
  id: string;
  // Similar for productId, it doesn't come from the API but we add it during client processing
  productId: string;
  data: string;
  dataTitle: string;
  style?: string;
  styleTitle?: string;
  visible: boolean;
}

/**
 * @property anyText - search product properties with the given text
 * @property serviceType - search products with this service type (WMS, WFS, ...)
 * @property type - search products with this type (RASTER, VECTOR, ...)
 * @property startDate - start date of the time filter
 * @property endDate - end date of the time filter;
 */
export interface ProductFilter {
  anyText?: string;
  serviceType?: ServiceType;
  type?: ProductType;
  startDate?: string;
  endDate?: string;
}

export const filtersEqual = (filterA: ProductFilter, filterB: ProductFilter) => {
  return filterA.anyText === filterB.anyText
         && filterA.type === filterB.type
         && filterA.serviceType === filterB.serviceType
         && filterA.startDate === filterB.startDate
         && filterA.endDate === filterB.endDate;
};

export const toProductLink = (product: Product) => `/products/${product.id}`;
