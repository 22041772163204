import {createBounds} from "@luciad/ria/shape/ShapeFactory";
import {Map} from "@luciad/ria/view/Map";
import * as React from "react";
import {Button} from "react-bootstrap";
import {defineMessages, InjectedIntlProps, injectIntl} from "react-intl";
import {Bounds, SpatialModel} from "../../../model";
import {equals} from "../../util/ArrayUtil";
import {FullscreenUtil} from "../../util/FullscreenUtil";
import {Logger} from "../../util/Logger";
import {LcdIcon} from "../icon/LcdIcon";
import {PreviewMetadata} from "./model";
import {getBounds} from "./RiaApi";
import {RIAMap} from "./RIAMap";

const RESTRICT_NAVIGATION_BOUNDS: Bounds = {
  x: -180,
  width: 360,
  y: -90,
  height: 180,
};

interface PreviewerProps {
  wmsBaseUrl: string;
  dataToVisualize: SpatialModel[];
  shouldFit: boolean;
  shouldAnimateFit?: boolean;
  height?: string;
  getPreviewMetadataFunction?: (id: string) => Promise<PreviewMetadata>;
}

interface PreviewerState {
  isFullscreen: boolean;
  isPreviewable: boolean;
  isGeoreferenced: boolean;
  isEmpty: boolean;
  shouldForceToPreview: boolean;
}

const PREVIEW_MESSAGES = defineMessages({
  showingBoundsOnly: {
    id: "studio.preview.showing-bounds-only",
    defaultMessage: "Showing bounds only.",
  },
  clickToLoadData: {
    id: "studio.preview.click-to-load-data",
    defaultMessage: "Click to load data.",
  },
  loadData: {
    id: "studio.preview.load-data",
    defaultMessage: "Load data.",
  },
  dataNotGeoreferenced: {
    id: "studio.preview.no-geographic-model-reference",
    defaultMessage: "No geographic model reference.",
  },
  dataCantBePreviewed: {
    id: "studio.preview.data-cant-be-previewed",
    defaultMessage: "The data can't be previewed.",
  },
  noData: {
    id: "studio.preview.no-data",
    defaultMessage: "No data.",
  },
  fit: {
    id: "studio.preview.toolbar.fit",
    defaultMessage: "Fit",
  },
  zoomIn: {
    id: "studio.preview.toolbar.zoomIn",
    defaultMessage: "Zoom in",
  },
  zoomOut: {
    id: "studio.preview.toolbar.zoomOut",
    defaultMessage: "Zoom out",
  },
  toggleFullscreen: {
    id: "studio.preview.toolbar.toggleFullscreen",
    defaultMessage: "Toggle fullscreen",
  },
});

class PreviewerComponent extends React.Component<PreviewerProps & InjectedIntlProps, PreviewerState> {

  _logger: Logger = Logger.getLogger("Previewer");

  map: Map;
  _previewerDivRef: HTMLDivElement;
  savedViewExtents = null;
  mapIdleListener = null;

  constructor(props) {
    super(props);
    this.state = {
      isFullscreen: false,
      isPreviewable: false,
      isGeoreferenced: false,
      isEmpty: false,
      shouldForceToPreview: false,
    };
  }

  componentDidMount() {
    this.getPreviewMetadata(this.props.dataToVisualize);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // V181-984: To prevent making multiple requests for the same data item, fetch the previewable result if the selected item has been changed.
    if (nextProps.dataToVisualize.length > 0 && !equals(nextProps.dataToVisualize, this.props.dataToVisualize)) {
      this.getPreviewMetadata(nextProps.dataToVisualize);
    }
  }

  fit = () => {
    const {dataToVisualize} = this.props;

    this.map.mapNavigator.fit({
      bounds: getBounds(dataToVisualize),
      animate: {duration: 1000, ease: (n) => n}, // ease should be optional in type definition
      fitMargin: "0px",
    }).catch((error) => {
      this._logger.error("Error occurred while fitting on data", error);
    });
  }

  setMap = (map: Map) => {
    this.map = map;
  }

  handleZoomInClick = () => {
    if (this.map) {
      this.map.mapNavigator.zoom({factor: 2, animate: true});
    }
  }

  handleZoomOutClick = () => {
    if (this.map) {
      this.map.mapNavigator.zoom({factor: 1 / 2, animate: true});
    }
  }

  handlePreviewClick = () => {
    this.setState(Object.assign({}, this.state, {isPreviewable: !this.state.isPreviewable}));
  }

  toggleFullscreen = () => {
    const fullScreenSwitched = () => {
      this.map.resize();
      if (this.savedViewExtents) {
        this.restoreViewExtents(this.savedViewExtents);
        this.savedViewExtents = null;
      }
    };
    const onExit = () => {
      this.setState(Object.assign({}, this.state, {isFullscreen: false}), fullScreenSwitched);
    };
    const onActivate = () => {
      //The user can exit fullscreen at any time using the ESC key.
      //When this happens, there is no way to save the state _before_ the fullscreen exit.
      //The workaround for this is to keep track of map state with a onMapIdle listener, while fullscreen
      this.mapIdleListener = this.map.on("idle", () => {
        this.savedViewExtents = this.saveViewExtents();
      });
      this.setState(Object.assign({}, this.state, {isFullscreen: true}), fullScreenSwitched);
    };
    if (this.state.isFullscreen) {
      //Fullscreen was toggled by clicking on the button, see above for ESC key exit
      this.mapIdleListener.remove();
      this.mapIdleListener = null;
      this.savedViewExtents = this.saveViewExtents();
      FullscreenUtil.exitFullscreen();
    } else {
      this.savedViewExtents = this.saveViewExtents();
      FullscreenUtil.requestFullscreen(this._previewerDivRef, onActivate, onExit);
    }
  }

  saveViewExtents() {
    const viewBounds = createBounds(null, [0, this.map.viewSize[0], 0, this.map.viewSize[1]]);
    return this.map.viewToMapTransformation.transformBounds(viewBounds);
  }

  restoreViewExtents(viewExtents) {
    this.map.mapNavigator.fit({
      bounds: viewExtents,
      animate: {duration: 200, ease: (n) => n}, // ease should be optional in type definition
      fitMargin: "0px",
    }).catch((error) => {
      this._logger.error("Error occurred while restoring view extents", error);
    });
  }

  getPreviewMetadata = (dataToVisualize) => {
    const {getPreviewMetadataFunction} = this.props;

    if (getPreviewMetadataFunction && dataToVisualize && dataToVisualize.length !== 0) {

      const promises = [];
      let isPreviewableResult = true, shouldForceToPreviewResult = true, isGeoreferencedResult = true, emptyResult = true;

      dataToVisualize.forEach((dataItem) => {
        if (dataItem && dataItem.id) {
          const previewMetadataPromise = getPreviewMetadataFunction(dataItem.id);

          promises.push(previewMetadataPromise);
          previewMetadataPromise.then((previewMetadata: PreviewMetadata) => {
            isPreviewableResult = isPreviewableResult && previewMetadata.previewable;
            shouldForceToPreviewResult = shouldForceToPreviewResult && (previewMetadata.previewable || previewMetadata.shouldForceToPreview);
            isGeoreferencedResult = isGeoreferencedResult && previewMetadata.georeferenced;
            emptyResult = emptyResult && previewMetadata.empty;
          });
        }
      });

      Promise.all(promises).then(() => {
        this.setState({isPreviewable: isPreviewableResult, isGeoreferenced: isGeoreferencedResult, isEmpty: emptyResult, shouldForceToPreview: shouldForceToPreviewResult});
      }).catch((error) => {
        this._logger.error("Error occured while loading preview settings.", error);
      });
    } else {
      this.setState({isPreviewable: true, shouldForceToPreview: false, isGeoreferenced: true, isEmpty: false});
    }
  }

  render() {
    const {wmsBaseUrl, dataToVisualize, shouldFit, shouldAnimateFit, height, intl} = this.props;
    const {isFullscreen, isPreviewable, shouldForceToPreview, isGeoreferenced, isEmpty} = this.state;

    const fullScreenStyleOverride = isFullscreen ? {width: "100%", height: "100%"} : {};
    const riaMapHeightNotFullScreen = height || "600px";
    const riaMapHeight = isFullscreen ? "100%" : riaMapHeightNotFullScreen;
    const dataExist = dataToVisualize && dataToVisualize.length !== 0;
    const showNotDataMessage = dataExist && isEmpty;
    const showNotGeoreferencedMessage = dataExist && !isEmpty && !isGeoreferenced;
    const showPreviewButton = dataExist && !isPreviewable && shouldForceToPreview && isGeoreferenced && !isEmpty;
    const showCantPreviewMessage = dataExist && !isPreviewable && !shouldForceToPreview && isGeoreferenced && !isEmpty;
    const showPreview = isPreviewable && isGeoreferenced && !isEmpty;

    return (
        <div className="previewer" ref={(ref) => this._previewerDivRef = ref} style={fullScreenStyleOverride}>
          <div className="previewToolbar">
            <Button className="btn-icon" onClick={() => this.fit()}
                    title={intl.formatMessage(PREVIEW_MESSAGES.fit)}><LcdIcon icon="spot"/></Button>
            <Button className="btn-icon" onClick={this.handleZoomInClick}
                    title={intl.formatMessage(PREVIEW_MESSAGES.zoomIn)}><LcdIcon icon="zoom-in"/></Button>
            <Button className="btn-icon" onClick={this.handleZoomOutClick}
                    title={intl.formatMessage(PREVIEW_MESSAGES.zoomOut)}><LcdIcon icon="zoom-out"/></Button>
            <Button className="btn-icon" onClick={this.toggleFullscreen}
                    title={intl.formatMessage(PREVIEW_MESSAGES.toggleFullscreen)}>
              <LcdIcon icon={isFullscreen ? "resize-small" : "fullscreen"}/>
            </Button>
          </div>
          {
            showNotDataMessage &&
            <div id="preview-notification">
              {intl.formatMessage(PREVIEW_MESSAGES.dataCantBePreviewed)}
              <br/>
              {intl.formatMessage(PREVIEW_MESSAGES.noData)}
            </div>
          }
          {
            showNotGeoreferencedMessage &&
            <div id="preview-notification">
              {intl.formatMessage(PREVIEW_MESSAGES.dataCantBePreviewed)}
              <br/>
              {intl.formatMessage(PREVIEW_MESSAGES.dataNotGeoreferenced)}
            </div>
          }
          {
            !showCantPreviewMessage && showPreviewButton &&
            <Button className="btn-icon" id="preview-button" onClick={this.handlePreviewClick}
                    title={intl.formatMessage(PREVIEW_MESSAGES.loadData)}>
              {intl.formatMessage(PREVIEW_MESSAGES.showingBoundsOnly)}
              <br/>
              {intl.formatMessage(PREVIEW_MESSAGES.clickToLoadData)}
            </Button>
          }
          {
            showCantPreviewMessage && !showPreviewButton &&
            <div id="preview-notification">
              {intl.formatMessage(PREVIEW_MESSAGES.showingBoundsOnly)}
              <br/>
              {intl.formatMessage(PREVIEW_MESSAGES.dataCantBePreviewed)}
            </div>
          }
          <div className="previewOverlay"></div>
          <RIAMap style={{width: "100%", height: riaMapHeight}}
                  wmsUrl={wmsBaseUrl}
                  dataToVisualize={dataToVisualize}
                  shouldFit={shouldFit}
                  shouldAnimateFit={shouldAnimateFit || false}
                  shouldHideLayerTree={true}
                  shouldAddGridLayer={false}
                  restrictNavigationBounds={RESTRICT_NAVIGATION_BOUNDS}
                  onMapInitialized={this.setMap}
                  showPreview={showPreview}
          />
        </div>
    );
  }
}

export const Previewer = injectIntl(PreviewerComponent);
