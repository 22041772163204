import {FileInfo} from "../../data/model";
import {Product} from "../../products/model";
import {getEndPointURL, Service} from "../model";

const getProductBrowseURL = (service: Service, product: Product) => {

  if (product && service) {
    return getEndPointURL(service) + "/" + product.name;
  }
};

const getFileDownloadURL = (service: Service, product: Product) => {
  if (product && service) {
    const content = product && product.contents && product.contents.length > 0 && product.contents[0];
    //const importedData = content.data;

    // TODO: this is an undesired change in functionality, due to the fact that StyledData objects no longer have
    // a reference to the actual importedData, thus we cannot get the filename. Needs to be resolved.
    return getProductBrowseURL(service, product);
    //return _getFileDownloadURL(service, product, importedData.fileName);
  }
};

const getFileDownloadURLByImportedData = (service: Service, product: Product, fileInfo: FileInfo) => {
  return _getFileDownloadURL(service, product, fileInfo.filePath);
};

const _getFileDownloadURL = (service: Service, product: Product, file: string) => {
  if (service && product) {
    const downloadURL = getProductBrowseURL(service, product) + "/" + file;
    return encodeURI(downloadURL);
  }
};

export {
  getProductBrowseURL,
  getFileDownloadURL,
  getFileDownloadURLByImportedData,
};
