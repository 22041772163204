import * as React from "react";
import {InjectedIntlProps, injectIntl} from "react-intl";
import {Typeahead} from "../common/ui/typeahead/Typeahead";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import {DEFAULT_STYLE, MODEL_MESSAGES, Style} from "./model";

interface StyleTypeaheadProps {
  value: Style;
  onChange: (newValue: Style) => void;
  validate?: (suggestion: Style) => boolean;
  placeholder?: string;
}

export const AbstractStyleTypeahead = Typeahead<Style>();

export class StyleTypeaheadComponent extends React.Component<StyleTypeaheadProps & WithApiProperties & InjectedIntlProps, {}> {
  static MAX_RESULTS = 10;

  fetchSuggestions = (value: string) => this.props.api.getStyles({anyText: value + "*"})
      .then((data) => {
            const {validate} = this.props;
            const filteredData = validate ? data.filter(validate) : data;
            return [DEFAULT_STYLE].concat(filteredData.slice(0, StyleTypeaheadComponent.MAX_RESULTS));
          },
      )

  renderSuggestion = (style: Style, shortForm = false): string => {
    return (style === DEFAULT_STYLE || shortForm) ? (style.title ||
                                                     this.props.intl.formatMessage(MODEL_MESSAGES.noMetaTitle))
                                                  : `${style.title}`;
  }

  render() {
    const {value, onChange, placeholder} = this.props;
    return (
        <AbstractStyleTypeahead value={value}
                                fetchSuggestions={this.fetchSuggestions}
                                renderSuggestion={this.renderSuggestion}
                                onChange={onChange}
                                placeholder={placeholder}
        />
    );
  }
}

export const StyleTypeahead = injectIntl(WithApi(StyleTypeaheadComponent));
