import * as React from "react";
import * as ReactDOM from "react-dom";

interface RenderInBodyProps {
  top: number;
  left: number;
  width: number;
}

export class RenderInBody extends React.Component<RenderInBodyProps, {}> {

  _content: HTMLDivElement;

  componentDidMount() {
    this._content = document.createElement("div");
    this._content.classList.add("renderInBody");
    this._content.style.position = "absolute";
    document.body.appendChild(this._content);
    this._renderContent();
  }

  componentDidUpdate() {
    this._renderContent();
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this._content);
    document.body.removeChild(this._content);
  }

  _renderContent() {
    this._content.style.top = this.props.top + "px";
    this._content.style.left = this.props.left + "px";
    this._content.style.width = this.props.width + "px";
    ReactDOM.render((<div>{this.props.children}</div>), this._content);
  }

  render() {
    return <div></div>; //render a placeholder
  }
}
