import * as React from "react";
import {Nav, NavItem} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {LinkContainer} from "react-router-bootstrap";

export class SettingsNavigation extends React.Component<{}, {}> {

  render() {
    return (
        <Nav stacked={true}>
          <LinkContainer to="/settings/dataroots"><NavItem><FormattedMessage
              id="studio.settings-navigation.link-data-roots" defaultMessage="Data roots"/></NavItem></LinkContainer>
          {/*<LinkContainer to="/settings/defaults"><NavItem>Defaults</NavItem></LinkContainer>*/}
          <LinkContainer to="/settings/logs"><NavItem><FormattedMessage id="studio.settings-navigation.link-logs"
                                                                        defaultMessage="Logs"/></NavItem></LinkContainer>
          <LinkContainer to="/settings/status"><NavItem><FormattedMessage id="studio.settings-navigation.link-status"
                                                                          defaultMessage="Status"/></NavItem></LinkContainer>
        </Nav>
    );
  }

}
