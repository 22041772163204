import * as React from "react";
import {Alert, Button, Tab, Tabs} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailMeta} from "../common/ui/detailsection/DetailMeta";
import {createCommonMetadataFields, DetailView} from "../common/ui/detailsection/DetailView";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {Previewer} from "../common/ui/riamap/Previewer";
import {UploadOptions} from "../common/ui/upload/UploadOptions";
import {FileUploadAndInjectedProps, WithFileUpload} from "../common/ui/upload/WithFileUpload";
import {getParameterFromOwnProps} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import * as paths from "../paths";
import {Product} from "../products/model";
import {actions} from "./actions";
import {getEndPointURL, Service, ServiceTypeDetails, supportsProducts} from "./model";
import {selectors} from "./selectors";
import {ServiceDetailProducts} from "./ServiceDetailProducts";
import {ServiceGetCapabilitiesButton} from "./ServiceGetCapabilitiesButton";
import {ServiceStartStopButton} from "./ServiceStartStopButton";

interface ServiceProductsProps {
  serviceId: string;
  service: Service;
  products: Product[];
  loadService: () => void;
  loadServiceTypes: () => void;
  serviceTypeDetails: ServiceTypeDetails;
  serviceChanged: (newService: Service) => void;
}

const SERVICE_DETAIL_MESSAGES = defineMessages({
  id: {id: "studio.services.service-detail.id", defaultMessage: "ID"},
  name: {id: "studio.services.service-detail.name", defaultMessage: "Name"},
  type: {id: "studio.services.service-detail.type", defaultMessage: "Type"},
  status: {id: "studio.services.service-detail.status", defaultMessage: "Status"},
  endpointURL: {id: "studio.services.service-detail.url", defaultMessage: "Endpoint URL"},
  preprocessingOutputPath: {id: "studio.services.service-detail.preprocessingOutputPath", defaultMessage: "Preprocessed Contents"},
  metadataLabel: {id: "studio.services.service-detail.metadata-header", defaultMessage: "Metadata"},
  productsLabel: {id: "studio.services.service-detail.products-header", defaultMessage: "Products"},
});

type ServiceDetailProps = ServiceProductsProps & RouteComponentProps<{ id: string }>;

class ServiceDetailReactComponent extends React.Component<ServiceDetailProps & InjectedIntlProps & WithApiProperties & FileUploadAndInjectedProps, { key: string}> {

  constructor(props, context) {
    super(props, context);
    this.state = {
      key: "metadata",
    };
  }
  componentDidMount() {
    this.props.loadService();
    this.props.loadServiceTypes();
  }

  renderProductsSection() {
    const {service} = this.props;
    if (supportsProducts(service)) {
      return <ServiceDetailProducts service={service}/>;
    }
    return (
        <Alert bsStyle="info">
          <FormattedMessage id="studio.services.service-detail.support-error"
                            defaultMessage="This service does not support products."/>
        </Alert>
    );
  }

  getDetailPane = () => {
    const getPreviewMetadataFunction = this.props.api.getProductPreviewMetadata;
    return <Previewer wmsBaseUrl={paths.PREVIEW_PRODUCT_PATH}
                      dataToVisualize={(this.props.products) || []}
                      shouldFit={true}
                      getPreviewMetadataFunction={getPreviewMetadataFunction}
    />;
  }

  getMasterPane = () => {
    const {service, products, serviceChanged, serviceTypeDetails} = this.props;
    const ID = this.props.match.params.id;
    if (!service) {
      return (
          <div>
            <h2><FormattedMessage id="studio.services.service-detail.ID-error"
                                  defaultMessage="Could not find service with id: {ID}" values={{ID}}/></h2>
            <p><Link to="/services"><FormattedMessage id="studio.services.service-detail.back-to-overview"
                                                      defaultMessage="Go back to services overview"/></Link></p>
          </div>
      );
    }

    const endPointUrl = getEndPointURL(service, products); //service.endpointUrl already includes /ogc
    const fields = [
      {
        key: "Id",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.id),
        value: service.id,
      },
      {key: "Name", name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.name), value: service.name},
      {
        key: "Type",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.type),
        value: service.type.toUpperCase(),
      },
      {key: "Status", name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.status), value: service.status},
      {
        key: "Endpoint URL",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.endpointURL),
        value: endPointUrl,
      },
    ];

    if (service.preprocessingOutputPath) {
      fields.push({
        key: "Preprocessed Contents",
        name: this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.preprocessingOutputPath),
        value: service.preprocessingOutputPath,
      });
    }

    return (
        <div>
          <DetailHeader title={service.name}/>

          <ActionBar>
            <ServiceStartStopButton service={service}/>
            <ServiceGetCapabilitiesButton service={service} serviceTypeDetails={serviceTypeDetails} />
            <Button onClick={this.props.openUpload}><LcdIcon icon="import"/>
              <FormattedMessage id="studio.services.service-detail.import-metadata" defaultMessage="Import metadata"/>
            </Button>
            <Button onClick={() => { this.props.api.downloadServiceMetadata(service); }}>
              <LcdIcon icon="download-alt"/><FormattedMessage
                id="studio.services.service-detail.download-metadata"
                defaultMessage="Download metadata"/>
            </Button>
          </ActionBar>
          <Tabs id="ServiceDetailTabs" bsStyle="pills" justified={false} animation={true} className={"tabs"} activeKey={this.state.key} onSelect={(key) => this.setState({ key })}>
            <Tab eventKey="metadata" title={this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.metadataLabel)}>
              <DetailMeta metadata={service}
                          updateEntity={(newMetadata) => serviceChanged(
                              Object.assign({}, service, {
                            title: newMetadata.title,
                            keywords: newMetadata.keywords,
                            abstractText: newMetadata.abstractText,
                          }))}
              />
              <DetailView fields={fields.concat(createCommonMetadataFields(service, this.props.intl))}/>
            </Tab>
            <Tab eventKey="products" title={this.props.intl.formatMessage(SERVICE_DETAIL_MESSAGES.productsLabel)}>
              <div style={{marginTop: 20}}>
                {this.renderProductsSection()}
              </div>
            </Tab>
          </Tabs>
        </div>
    );
  }

  render() {
    return (
        <div>
          <MasterDetailLayout masterPane={this.getMasterPane()}
                              detailPane={this.getDetailPane()}
                              evenSplit={true}/>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  const service = selectors.getServiceById(state, id);
  const serviceTypeDetails = getServiceTypeDetails(service, selectors.getAllServiceTypes(state));
  return {
    serviceId: id,
    service,
    serviceTypeDetails,
    products: selectors.getServiceProducts(state, id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    serviceId: id,
    loadService: () => dispatch(actions.requestItemById(id)),
    loadServiceTypes: () => dispatch(actions.loadAllServiceTypes()),
    serviceChanged: (newService: Service) => dispatch(actions.updateService(newService)),
    createFormData: (uploadFiles: File[]) => {
      const data = new FormData();
      data.append("serviceId", id);
      data.append("metadataFile", uploadFiles[0]);
      return data;
    },
  };
};

const getServiceTypeDetails = (service: Service, serviceTypeDetails: ServiceTypeDetails[]) => {
  if (service && serviceTypeDetails) {
    return serviceTypeDetails.find((serviceConf) => serviceConf.serviceType === service.type);
  }

  return undefined;
};

const serviceMetadataUploadOptions: UploadOptions = {
  allowedFileExtension: ".xml",
  allowMultipleFiles: false,
  uploadUri: "/upload/service-metadata",
};

export const ServiceDetailComponent = injectIntl(ServiceDetailReactComponent);
const ServiceDetailPageComponentWithFileUpload = WithFileUpload(serviceMetadataUploadOptions)(ServiceDetailComponent);

export const ServiceDetail = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WithApi(ServiceDetailPageComponentWithFileUpload)));
