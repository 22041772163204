import * as React from "react";
import {FormattedMessage} from "react-intl";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailSection, DetailSectionProps} from "../common/ui/detailsection/DetailSection";
import {DetailView} from "../common/ui/detailsection/DetailView";
import {PreviewMetadata} from "../common/ui/riamap/model";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import * as paths from "../paths";
import {Product} from "./model";

export class ProductListDetailSectionComponent extends DetailSection<DetailSectionProps<Product> & WithApiProperties, Product> {

  loadData(nextProps: DetailSectionProps<Product>): Promise<Product> {
    if (nextProps.selection.length === 1) {
      const productId = nextProps.selection[0].id;
      return this.props.api.getProductById(productId);
    }
    return Promise.resolve(null);
  }

  getPreviewBaseUrl(): string {
    return paths.PREVIEW_PRODUCT_PATH;
  }

  getPreviewMetadataFunction(): (dataId: string) => Promise<PreviewMetadata> {
    return this.props.api.getProductPreviewMetadata;
  }

  renderContent() {
    const {isLoading, error} = this.state;
    const {selection} = this.props;

    if (selection.length === 0) {
      return (
          <div className="emptyDetailPlaceholder">
            <FormattedMessage id="studio.products.product-list-detail.empty-detail-placeholder"
                              defaultMessage="Select some data in the table."/>
          </div>
      );
    }
    if (selection.length > 1) {
      return <p><FormattedMessage id="studio.products.product-list-detail.selection-length"
                                  defaultMessage="Selected {length} items." values={{length: selection.length}}/></p>;
    }
    if (selection.length === 1 && this.state.data && !isLoading && !error) {
      const product = this.state.data;
      return (
          <div className="details">
            <DetailHeader title={product.title}/>
            <DetailView fields={[
              {
                key: "Keywords",
                name: <FormattedMessage id="studio.products.product-list-detail.keywords" defaultMessage="Keywords"/>,
                value: product.keywords.join(", "),
              },
            ]}/>
          </div>
      );
    }
    return super.renderContent();
  }
}

export const ProductListDetailSection = WithApi(ProductListDetailSectionComponent);
