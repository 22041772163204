import {combineReducers} from "redux";
import {ConfigurationInfo} from "../model";
import {actionTypes} from "./actions";
import {moduleName} from "./selectors";

export interface StartupState {
  configurationInfo: ConfigurationInfo;
}

const configurationInfoReducer = (state: ConfigurationInfo = null, action) => {
  switch (action.type) {
  case actionTypes.CONFIGURATION_LOADED:
    return Object.assign({}, state, action.payload);
  case actionTypes.MISSING_ME_QUERY_PARAMS:
    return Object.assign({}, state, {...action.payload, missingMappEnterpriseInfo: true});
  case actionTypes.INVALID_ME_QUERY_PARAMS:
    return Object.assign({}, state, {...action.payload, invalidMappEnterpriseInfo: true});
  case "init":
    return { securityInfo: { isMappEnterprise: false }};
  }
  return state;
};

const reducer = combineReducers<StartupState>({
  configurationInfo: configurationInfoReducer,
});

export {
  moduleName,
  reducer,
};
