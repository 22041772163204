import * as React from "react";
import {defineMessages, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {ActionBar} from "../common/ui/actionbar/ActionBar";
import {DetailHeader} from "../common/ui/detailsection/DetailHeader";
import {DetailMeta} from "../common/ui/detailsection/DetailMeta";
import {createCommonMetadataFields, DetailView, FieldInfo, Formats} from "../common/ui/detailsection/DetailView";
import {SectionHeader} from "../common/ui/detailsection/SectionHeader";
import {MasterDetailLayout} from "../common/ui/layouts/MasterDetailLayout";
import {Previewer} from "../common/ui/riamap/Previewer";
import {getParameterFromOwnProps} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import * as paths from "../paths";
import {PublishProductInServiceButton} from "../services/CreateServiceButton";
import {actions} from "./actions";
import {Product} from "./model";
import {ProductContentList, productContentListDimensionsOnDetailPage} from "./ProductContentList";
import {selectors} from "./selectors";
import {Tab, Tabs} from "react-bootstrap";

interface ProductDetailPageStateProps {
  product: Product;
}

interface ProductDetailPageDispatchProps {
  loadProduct: () => void;
  productChanged: (newProduct: Product) => void;
  // No need to check if product is part of service, can update directly.
  productNonEssentialMetadataChanged: (newProduct: Product) => void;
}

interface ProductDetailState {
  shouldFit: boolean;
  key: string;
}

const PRODUCT_DETAIL_MESSAGES = defineMessages({
  name: {id: "studio.products.product-detail.name", defaultMessage: "Name"},
  type: {id: "studio.products.product-detail.type", defaultMessage: "Type"},
  metadataLabel: {id: "studio.products.product-detail-page.metadata", defaultMessage: "Metadata"},
  contentLabel: {id: "studio.products.product-detail-page.content", defaultMessage: "Content"},
});

type ProductDetailProps =
    ProductDetailPageStateProps
    & ProductDetailPageDispatchProps
    & RouteComponentProps<{ id: string }>;

class ProductDetailPageReactComponent extends React.Component<ProductDetailProps & InjectedIntlProps & WithApiProperties, ProductDetailState> {

  constructor(props) {
    super(props);
    this.state = {
      shouldFit: true,
      key: "metadata",
    };
  }

  componentDidMount() {
    this.props.loadProduct();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // V170-2140, LF-1634: The preview map should fit on the bounds of the product,
    // but it should keep the current view if the user changes the order of the data
    if (this.props.product != null && nextProps.product != null
        && this.props.product.id === nextProps.product.id) {
      this.setState({shouldFit: false});
    }
  }

  getMasterPane = () => {
    const {product, productChanged, productNonEssentialMetadataChanged} = this.props;
    if (!product) {
      return (
          <div>
            <h2><FormattedMessage id="studio.products.product-detail-page.find-product-error"
                                  defaultMessage="Could not find product with id: {ID}"
                                  values={{ID: this.props.match.params.id}}/></h2>
            <p><Link to="/products"><FormattedMessage id="studio.products.product-detail-page.back-to-products-overview"
                                                      defaultMessage="Go back to products overview"/></Link></p>
          </div>
      );
    }
    const fields: FieldInfo[] = [
      {
        key: "Name",
        name: this.props.intl.formatMessage(PRODUCT_DETAIL_MESSAGES.name),
        value: product.name,
        format: Formats.TEXT_EDIT,
        onChange: (newName) => {
          this.props.product.name = newName;
          productChanged(this.props.product);
        },
      },
      {
        key: "Type",
        name: this.props.intl.formatMessage(PRODUCT_DETAIL_MESSAGES.type),
        value: product.type && product.type.toString(),
        format: Formats.TEXT,
      },
    ];

    return (
        <div>
          <DetailHeader title={product.title}/>
          <ActionBar>
            <PublishProductInServiceButton disabled={false} inputProducts={[product]}/>
          </ActionBar>

          <SectionHeader><FormattedMessage id="studio.products.product-detail-page.metadata" defaultMessage="Metadata"/></SectionHeader>

          <DetailMeta metadata={product}
                      updateEntity={(newMetadata) => productChanged(
                          Object.assign({}, product, newMetadata))}
                      updateAbstract={(newMetadata) => productNonEssentialMetadataChanged(
                          Object.assign({}, product, newMetadata))}
                      updateKeywords={(newMetadata) => productNonEssentialMetadataChanged(
                          Object.assign({}, product, newMetadata))}
          />

          <DetailView fields={fields.concat(createCommonMetadataFields(product, this.props.intl))}/>

          <SectionHeader><FormattedMessage id="studio.products.product-detail-page.content"
                                           defaultMessage="Content"/></SectionHeader>

          <ProductContentList productId={product.id}
                              withVisibilityToggle={true}
                              calculateDimensions={productContentListDimensionsOnDetailPage}/>
        </div>
    );
  }

  setName = (newName) => {
    this.props.product.name = newName;
  }

  getDetailPane = () => {
    const getProductPreviewMetadataFunction = this.props.api.getProductPreviewMetadata;
    return (<Previewer wmsBaseUrl={paths.PREVIEW_PRODUCT_PATH}
                       dataToVisualize={this.props.product ? [this.props.product] : []}
                       shouldFit={this.state.shouldFit}
                       getPreviewMetadataFunction={getProductPreviewMetadataFunction}
    />);
  }

  render() {
    return (
        <div>
          <MasterDetailLayout masterPane={this.getMasterPane()}
                              detailPane={this.getDetailPane()}
                              evenSplit={true}
          />
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    product: selectors.getProductById(state, id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = getParameterFromOwnProps(ownProps, "id"); //maybe injected by withRouter
  return {
    loadProduct: () => dispatch(actions.getProduct(id)),
    productChanged: (product: Product) => {
      dispatch(actions.updateProductIfNoWarnings(product, ownProps.intl));
    },
    productNonEssentialMetadataChanged: (product: Product) => {
      dispatch(actions.updateProduct(product));
    },
  };
};
// injectIntl is needed in both statements - the first for when we use ProductDetailPageComponent on its own (i.e. unit tests),
// and the second for use in the actual product. If we don't do the second, then ownProps.intl is undefined in mapDispatchToProps
export const ProductDetailPageComponent = injectIntl(WithApi(ProductDetailPageReactComponent));
export const ProductDetailPage = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductDetailPageComponent)));
