import {EntityModel} from "../model";
import * as paths from "../paths";
import {Product} from "../products/model";
import {getProductBrowseURL} from "./fileserver/Util";

export enum ServiceType {
  WMS = "wms",
  WMTS = "wmts",
  WFS = "wfs",
  CSW = "csw",
  LTS = "lts",
  FILE_SERVER = "fileserver",
}

export enum ServiceStatus {
  RUNNING = "Running",
  PENDING = "Pending",
  STOPPED = "Stopped",
}

export interface Service extends EntityModel {
  type: ServiceType;
  isoMetadataXmlContent?: string;
  status?: ServiceStatus;
  creationTime?: string;
  updateTime?: string;
  startedTime?: string;
  createdBy?: {
    username: string;
  };
  endpointPath?: string;
  products?: Product[];
  canDelete: boolean;
  preprocessingOutputPath?: string;
}

export interface ServiceTypeDetails {
  serviceType: ServiceType;
  maxAllowedProductCount?: number;
  hasCapabilities: boolean;
  needsPreprocessing: boolean;
}

export const toServiceLink = (service: Service) => `/services/${service.id}`;

export const canRemoveService = (service: Service) => {
  return service.canDelete;
};

export const supportsProducts = (service: Service) => {
  return service && service.type !== ServiceType.CSW;
};

export const hasEndPointURL = (service: Service) => {
  if (service) {
    if (service.type === ServiceType.FILE_SERVER) {
      return service.products !== null && service.products !== undefined && service.products.length === 1;
    }
    return true;
  }
  return false;
};

export const getEndPointURL = (service: Service, products?: Product[]) => {
  if (service && service.type === ServiceType.FILE_SERVER) {
    if (products && products.length === 1) {
      const product = products[0];
      return product && getProductBrowseURL(service, product);
    }
  }
  return paths.getAbsoluteBaseUrl() + service.endpointPath;
};
