import {defineMessages} from "react-intl";
import {FileMetadata} from "../common/model";
import {PaginationRequestParams} from "../common/util/pagination/PaginationUtil";
import {SpatialModel} from "../model";

export interface ImportedDataSearchFilter extends PaginationRequestParams {
  anyText?: string;
  type?: string;
  startDate?: string;
  endDate?: string;
  onlyTopLevelData?: boolean;
}

export const filtersEqual = (filterA: ImportedDataSearchFilter, filterB: ImportedDataSearchFilter) => {
  return filterA.anyText === filterB.anyText
         && filterA.type === filterB.type
         && filterA.startDate === filterB.startDate
         && filterA.endDate === filterB.endDate;
};

export interface ImportedData extends SpatialModel {
  creationTime?: string;
  filePath?: string;
  defaultStyleId?: string;
  fileMetadata  ?: FileMetadata;
  type: string;
}

export class FileStatus {
  static SUCCESS = "Success";
  static FAILED = "Failed";
  static SKIPPED = "Skipped";
}

export const FILE_STATUS_MESSAGES = defineMessages({
  success: {
    id: "studio.data.file-status.success",
    defaultMessage: "Success",
  },
  failed: {
    id: "studio.data.file-status.failed",
    defaultMessage: "Failed",
  },
  skipped: {
    id: "studio.data.file-status.skipped",
    defaultMessage: "Skipped",
  },
});

export interface FileInfo {
  id: number;
  fileName: string;
  filePath: string;
  fileExtension: string;
  fileSize: number;
  directory: boolean;
  mimeType: string;
  firstScanTime: string;
  lastScanTime: string;
  status?: FileStatus;
  error?: string;
  dataId?: string;
  dataTitle?: string;
  type?: string;
  styleId?: string;
  styleTitle?: string;
}
