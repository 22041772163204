import * as React from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Tab,
  Tabs,
} from "react-bootstrap";
import {defineMessages, FormattedMessage, InjectedIntl, InjectedIntlProps, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Action, Dispatch} from "redux";
import {
  Fields,
  FormErrors,
  getFormSyncErrors,
  hasSubmitSucceeded,
  InjectedFormProps,
  isPristine,
  isSubmitting,
  reduxForm,
  SubmissionError,
  submit,
} from "redux-form";
import {createCloseButton} from "../common/ui/FormButtons";
import {LcdIcon} from "../common/ui/icon/LcdIcon";
import {productValidation} from "../common/ui/validation/ProductValidation";
import {ServiceTypeValidation} from "../common/ui/validation/ServiceTypeValidation";
import {moveItem, removeItem} from "../common/util/ArrayUtil";
import {Logger} from "../common/util/Logger";
import {calculateDimensionBasedOnRowsBeforeScroll, isEmpty} from "../common/util/Util";
import {WithApi, WithApiProperties} from "../common/util/WithApi";
import * as paths from "../paths";
import {PendingProductForService, Product} from "../products/model";
import {ChooseFolderDialog} from "../settings/dataroots/ChooseFolderDialog";
import {DataRoot} from "../settings/dataroots/model";
import {actions} from "./actions";
import {Service, ServiceStatus, ServiceType, ServiceTypeDetails, toServiceLink} from "./model";
import {SERVICE_PRODUCT_LIST_ROW_HEIGHT, ServiceProductList} from "./ServiceProductList";
import InputGroup = require("react-bootstrap/lib/InputGroup");
import InputGroupAddon = require("react-bootstrap/lib/InputGroupAddon");
import InputGroupButton = require("react-bootstrap/lib/InputGroupButton");

// Used to "catch" newly created service for linking, which happens while submitting the form.
let _newService = null;

export interface CreateServiceFormData {
  serviceTitle: string;
  serviceName: string;
  serviceType: ServiceType;
  serviceAbstract: string;
  serviceKeywords: string;
  immediatelyStartService: boolean;
  servicePreprocessingPath: string;
  serviceProducts: Product[];
}

interface CreateServiceFormOwnProps {
  inputProducts?: PendingProductForService[];
  serviceTypeValidation?: ServiceTypeValidation;
}

export type CreateServiceFormProps = CreateServiceFormOwnProps & InjectedIntlProps & WithApiProperties;

interface ComponentTypesState {
  dynamicServiceTypeDetails: ServiceTypeDetails[];
  serviceUrl?: string;
  renderServicePreprocessingPathField: boolean;
  showChooseDataRootDialog: boolean;
  preprocessingPath: string;
}

const FIELD_SERVICE_TITLE = "serviceTitle";
const FIELD_SERVICE_NAME = "serviceName";
const FIELD_SERVICE_TYPE = "serviceType";
const FIELD_SERVICE_PRODUCTS = "serviceProducts";
const FIELD_SERVICE_PREPROCESSING_PATH = "servicePreprocessingPath";

const LABEL_COL_WIDTH = 2;
const DEFAULT_SERVICE_TYPE_DETAILS: ServiceTypeDetails = {
  serviceType: ServiceType.WMS,
  hasCapabilities: true,
  needsPreprocessing: false,
};

// strip spaces, convert to lowercase and strip symbols
const toSafeName = (text) => text.replace(/\s/g, "_").toLowerCase().replace(/[^\s\w\-_]/g, "");
const canAutoFill = (field) => !field.meta.touched;

interface UrlComponentProps {
  serviceType: ServiceType;
  serviceName: string;
}

interface UrlComponentState {
  serviceUrl: string;
}

const SERVICE_FORM_MESSAGES = defineMessages({
  loadURIError: {
    id: "studio.create-service-form.uri-error",
    defaultMessage: "Error occurred while loading service uri",
  },
  serviceTypeError: {
    id: "studio.create-service-form.service-type-error",
    defaultMessage: "Error occurred while retrieving service types",
  },
  uniqueNameError: {
    id: "studio.create-service-form.unique-name-error",
    defaultMessage: "Error occurred while determining unique name for service",
  },
  similarNameError: {
    id: "studio.create-service-form.similar-name-error",
    defaultMessage: "Error fetching services with similar names",
  },
  chooseOutputDirectory: {
    id: "studio.create-service-form.choose-output-directory",
    defaultMessage: "Choose Output Directory",
  },
  chooseLocation: {
    id: "studio.create-service-form.choose-location",
    defaultMessage: "Choose Location",
  },
  submitFailed: {
    id: "studio.create-service-form.submit-failed",
    defaultMessage: "Service creation failed:",
  },
  submitting: {
    id: "studio.create-service-form.submitting",
    defaultMessage: "Creating service...",
  },
  metadataLabel: {id: "studio.services.service-detail.metadata-header", defaultMessage: "Metadata"},
  productsLabel: {id: "studio.services.service-detail.products-header", defaultMessage: "Products"},
  productsCountError: {
    id: "studio.create-service-form.service-products-count-error",
    defaultMessage: "{type} services can only have {count} product(s).",
  },
  unknownTypeError: {
    id: "studio.create-service-form.unknown-service-type-error",
    defaultMessage: "Couldn't find service type {type}",
  },
});

class UrlComponent extends React.Component<UrlComponentProps & WithApiProperties & InjectedIntlProps, UrlComponentState> {

  _logger: Logger = Logger.getLogger("services.CreateServiceForm.UrlComponent");

  _mounted: boolean;

  constructor(props) {
    super(props);
    this.state = {serviceUrl: ""};
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {serviceType, serviceName} = nextProps;
    const normalizedServiceName = toSafeName(serviceName);

    if (serviceType && normalizedServiceName) {
      this.props.api.getServiceUri(serviceType.toLowerCase(), normalizedServiceName)
          .then((serviceUri) => {
            const serviceUrl = `${paths.getAbsoluteBaseUrl()}${serviceUri}`;
            if (this._mounted) {
              this.setState({serviceUrl});
            }
          }).catch((error) => {
        this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.loadURIError), error);
      });
    } else {
      if (!this._mounted) {
        this.setState({serviceUrl: ""});
      }
    }
  }

  render() {
    return <strong>{this.state.serviceUrl}</strong>;
  }
}

class CreateServiceFormReactComponent extends React.Component<CreateServiceFormProps & InjectedFormProps<CreateServiceFormData, CreateServiceFormProps>, ComponentTypesState> {

  _logger: Logger = Logger.getLogger("services.CreateServiceForm.CreateServiceFormReactComponent");
  _defaultPreprocessingPath = null;

  constructor(props) {
    super(props);
    this.state = {
      dynamicServiceTypeDetails: [DEFAULT_SERVICE_TYPE_DETAILS],
      renderServicePreprocessingPathField: false,
      showChooseDataRootDialog: false,
      preprocessingPath: null,
    };
  }

  componentDidMount() {
    const {inputProducts = []} = this.props;
    _newService = null;
    if (inputProducts) {
      inputProducts.forEach(
          (inputProduct) => inputProduct.pendingServiceType = DEFAULT_SERVICE_TYPE_DETAILS.serviceType);
    }
    this.props.initialize({
      serviceTitle: "",
      serviceName: "",
      serviceType: DEFAULT_SERVICE_TYPE_DETAILS.serviceType,
      serviceAbstract: "",
      serviceKeywords: "",
      immediatelyStartService: true,
      serviceProducts: [...inputProducts],
      servicePreprocessingPath: "",
    });

    // fetch all available service types
    this.props.api.getEnabledServiceTypes()
        .then((serviceTypeDetails) => this.setState({dynamicServiceTypeDetails: serviceTypeDetails}))
        .catch((error) => {
          this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.serviceTypeError), error);
        });

    // default service title based on input products
    if (inputProducts && inputProducts[0]) {
      this.getUniqueName(inputProducts[0].title).catch((error) => {
        this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.uniqueNameError));
      });
    }
  }

  getUniqueName = (baseName: string) =>
      this.props.api.listServices({anyText: toSafeName(baseName) + "*"}).then(
          (servicesWithName) => {
            const defaultServiceTitle = servicesWithName.length ?
                                        baseName + "-" + (servicesWithName.length + 1) :
                                        baseName;
            this.props.autofill(FIELD_SERVICE_TITLE, defaultServiceTitle);
            this.props.autofill(FIELD_SERVICE_NAME, toSafeName(defaultServiceTitle));
          },
      ).catch((error) => {
        this._logger.error(this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.similarNameError), error);
      })

  handleTitleChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.serviceTitle.input.onChange(event);

    if (canAutoFill(fields[FIELD_SERVICE_NAME])) {
      const serviceTitle = event.target.value;
      const derivedServiceName = toSafeName(serviceTitle);
      this.props.change(FIELD_SERVICE_NAME, derivedServiceName);
      this.updatePreprocessingPath(derivedServiceName);
    }
  }

  handleServiceTypeChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.serviceType.input.onChange(event);

    const serviceType = event.target.value;

    if (this.props.inputProducts) {
      this.props.inputProducts.forEach((inputProduct) => inputProduct.pendingServiceType = serviceType);
      this.props.change(FIELD_SERVICE_PRODUCTS, [...this.props.inputProducts]);
    }

    if (fields[FIELD_SERVICE_PRODUCTS].input.value) {
      fields[FIELD_SERVICE_PRODUCTS].input.value.forEach(
          ((inputProduct) => inputProduct.pendingServiceType = serviceType));
    }

    const serviceConfiguration = this.state.dynamicServiceTypeDetails.find((aServiceConfiguration) => {
      return aServiceConfiguration.serviceType === serviceType;
    });

    if (serviceConfiguration && serviceConfiguration.needsPreprocessing) {
      if (!this._defaultPreprocessingPath) {
        // Makes a request with an empty service name to fetch the default preprocessing path
        this.props.api.getPreprocessingPath("").then((preprocessingPath) => {
          this._defaultPreprocessingPath = preprocessingPath;
        }).catch((error) => {
          this._logger.error("Error occurred while loading preprocessing path", error);
        });
      }

      const serviceName = fields[FIELD_SERVICE_NAME].input.value;
      this.props.api.getPreprocessingPath(serviceName).then((preprocessingPath) => {
        this.setState({
          renderServicePreprocessingPathField: true,
          preprocessingPath,
        });

        // Beware, this call should be after the setState call, otherwise react doesn't re-render the component.
        this.props.change(FIELD_SERVICE_PREPROCESSING_PATH, preprocessingPath);

      }).catch((error) => {
        this._logger.error("Error occurred while loading preprocessing path", error);

        this.resetPreprocessingPath();
      });
    } else {
      this.resetPreprocessingPath();
    }
  }

  handleServiceNameChange = (fields, event) => {
    //need to call the 'original' input.onChange handler as well, to avoid breaking redux-form
    fields.serviceName.input.onChange(event);

    const serviceName = toSafeName(event.target.value);
    this.updatePreprocessingPath(serviceName);
  }

  updatePreprocessingPath = (serviceName) => {
    if (this.state.renderServicePreprocessingPathField) {
      this.props.api.getPreprocessingPath(serviceName).then((preprocessingPath) => {
        this.setState({
          preprocessingPath,
        });
        this.props.change(FIELD_SERVICE_PREPROCESSING_PATH, preprocessingPath);
      }).catch((error) => {
        this._logger.error("Error occurred while loading preprocessing path", error);
        this.resetPreprocessingPath();
      });
    }
  }

  resetPreprocessingPath = () => {
    this.setState({
      preprocessingPath: null,
      renderServicePreprocessingPathField: false,
    });

    this.props.change(FIELD_SERVICE_PREPROCESSING_PATH, "");
  }

  showChooseDataRootDialog = () => {
    this.setState({showChooseDataRootDialog: true});
  }

  closeChooseDataRootDialog = () => {
    this.setState({showChooseDataRootDialog: false});
  }

  handleChooseDataRoot = (dataRoot: DataRoot) => {
    const dataRootPath = dataRoot.rootPath;
    this.setState({preprocessingPath: dataRootPath});
    this.props.autofill(FIELD_SERVICE_PREPROCESSING_PATH, dataRootPath);
    return Promise.resolve();
  }

  renderServiceTypes = () => this.state.dynamicServiceTypeDetails.map((serviceConfiguration) => {
    return <option key={serviceConfiguration.serviceType} value={serviceConfiguration.serviceType}>
      {serviceConfiguration.serviceType.toUpperCase()}
    </option>;
  })

  renderFields = (fields) => {
    const {renderServicePreprocessingPathField, preprocessingPath} = this.state;

    const fServiceTitle = fields[FIELD_SERVICE_TITLE];
    const fServiceName = fields[FIELD_SERVICE_NAME];
    const fServiceType = fields[FIELD_SERVICE_TYPE];
    const fServiceProducts = fields[FIELD_SERVICE_PRODUCTS];

    const {autofilled, error} = fServiceName.meta;
    const autoFillClass = autofilled ? "autofill" : "";

    const serviceProductsInput = fServiceProducts.input;
    const serviceTypeInput = fServiceType.input;
    const serviceNameInput = fServiceName.input;

    return (
        <div>
          <Tabs id="ServiceDetailTabs" bsStyle="pills" justified={false}
                animation={true} className={"tabs"} defaultActiveKey={"metadata"}>
            <Tab eventKey="metadata" title={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.metadataLabel)}>
              <div style={{margin: 20}}>
                <FormGroup controlId={fServiceTitle.input.name}
                           validationState={fServiceTitle.meta.error ? "error" : null}>
                  <Col sm={LABEL_COL_WIDTH}>
                    <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-title"
                                                    defaultMessage="Service title"/></ControlLabel>
                  </Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <FormControl type="text" {...fServiceTitle.input}
                                 onChange={this.handleTitleChange.bind(this, fields)}/>
                  </Col>
                </FormGroup>
                <FormGroup controlId={serviceTypeInput.name}>
                  <Col sm={LABEL_COL_WIDTH}>
                    <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-type"
                                                    defaultMessage="Service type"/></ControlLabel>
                  </Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <FormControl componentClass="select" {...serviceTypeInput}
                                 onChange={this.handleServiceTypeChange.bind(this, fields)}>
                      {this.renderServiceTypes()}
                    </FormControl>
                  </Col>
                </FormGroup>
                {serviceTypeInput.value === "lts" ?
                 <FormGroup controlId={serviceTypeInput.name}>
                   <Col sm={LABEL_COL_WIDTH}/>
                   <Col sm={12 - LABEL_COL_WIDTH}>
                     <small><strong><FormattedMessage id="studio.services.create-service-form.note"
                                                      defaultMessage="Note"/>&#58;&nbsp;</strong>
                       <FormattedMessage id="studio.services.create-service-form.lts-note"
                                         defaultMessage="LTS services will only start if all products contain either a Fusion coverage or elevation data"/>
                     </small>
                   </Col>
                 </FormGroup> : null
                }
                <FormGroup controlId={fServiceName.input.name} validationState={error ? "error" : null}>
                  <Col sm={LABEL_COL_WIDTH}>
                    <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-name"
                                                    defaultMessage="Service name"/></ControlLabel>
                  </Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <FormControl type="text" {...fServiceName.input} className={autoFillClass}
                                 onChange={this.handleServiceNameChange.bind(this, fields)}/>
                    {fServiceName.meta.error && <HelpBlock>{fServiceName.meta.error}</HelpBlock>}
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={LABEL_COL_WIDTH}><ControlLabel><FormattedMessage
                      id="studio.services.create-service-form.endpoint-url"
                      defaultMessage="Endpoint URL"/></ControlLabel></Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <UrlComponent api={this.props.api} serviceType={serviceTypeInput.value}
                                  serviceName={serviceNameInput.value} intl={this.props.intl}/>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={LABEL_COL_WIDTH}>
                    <ControlLabel><FormattedMessage id="studio.services.create-service-form.abstract"
                                                    defaultMessage="Abstract"/></ControlLabel>
                  </Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <FormControl componentClass="textarea" {...fields.serviceAbstract.input}/>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={LABEL_COL_WIDTH}>
                    <ControlLabel><FormattedMessage id="studio.services.create-service-form.keywords"
                                                    defaultMessage="Keywords"/></ControlLabel>
                  </Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <FormControl type="text" {...fields.serviceKeywords.input}/>
                  </Col>
                  <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                    <HelpBlock>
                      <FormattedMessage id="studio.services.create-service-form.keywords-help"
                                        defaultMessage="Enter a comma-separated list of keywords. For example: satellite,multispectral,landsat"/>
                    </HelpBlock>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={LABEL_COL_WIDTH}>
                    <ControlLabel><FormattedMessage id="studio.services.create-service-form.service-start"
                                                    defaultMessage="Start service?"/></ControlLabel>
                  </Col>
                  <Col sm={12 - LABEL_COL_WIDTH}>
                    <FormControl componentClass={Checkbox}
                                 checked={fields.immediatelyStartService.input.value}
                                 {...fields.immediatelyStartService.input}/>
                  </Col>
                </FormGroup>
                {
                  renderServicePreprocessingPathField &&
                  <FormGroup>
                    <Col sm={LABEL_COL_WIDTH}>
                      <ControlLabel>
                        <FormattedMessage
                            id="studio.create-service-form.preprocessing-output-path"
                            defaultMessage="Output Path"
                        />
                      </ControlLabel>
                    </Col>
                    <Col sm={12 - LABEL_COL_WIDTH}>
                      <InputGroup>
                        <FormControl disabled={true} className={"browse-data-root-input"} type="text"
                                     value={preprocessingPath}/>
                        <InputGroupAddon className={"browse-data-root-button"} onClick={this.showChooseDataRootDialog}>
                          <InputGroupButton>
                            <LcdIcon icon="folder-open"/>
                          </InputGroupButton>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col smOffset={LABEL_COL_WIDTH} sm={12 - LABEL_COL_WIDTH}>
                      <HelpBlock>
                        <FormattedMessage
                            id="studio.create-service-form.preprocessing-output-path-description"
                            defaultMessage="Output directory where the preprocessing results will be stored."
                        />
                      </HelpBlock>
                    </Col>
                  </FormGroup>
                }
              </div>
            </Tab>
            <Tab eventKey="products" title={this.props.intl.formatMessage(SERVICE_FORM_MESSAGES.productsLabel)}>
              <div style={{margin: 20}}>
                <FormGroup>
                  {this.createGeneralWarnings(this.props.serviceTypeValidation, serviceTypeInput)}
                  <ServiceProductList items={serviceProductsInput.value}
                                      onReorder={(oldIndex, newIndex, items) => serviceProductsInput.onChange(
                                          moveItem<Product>(items, oldIndex, newIndex))}
                                      onAdd={(newProductItem) => serviceProductsInput.onChange(
                                          [newProductItem, ...serviceProductsInput.value])}
                                      onRemove={(item, index) => serviceProductsInput.onChange(
                                          removeItem(serviceProductsInput.value, index))}
                                      serviceType={serviceTypeInput.value}
                                      noLinks
                                      showValidationBadge={true}
                                      calculateDimensions={calculateDimensionBasedOnRowsBeforeScroll(7,
                                          SERVICE_PRODUCT_LIST_ROW_HEIGHT)}
                  />
                </FormGroup>
              </div>
            </Tab>
          </Tabs>
        </div>
    );
  }

  confirmationComponent = (newService: Service) => {
    return (
        <div className="form-message">
          <h2><FormattedMessage
              id="studio.create-service-form.service-created"
              defaultMessage="Service successfully created"
          /></h2>
          {newService && newService.status && newService.status === ServiceStatus.PENDING &&
           <h4><FormattedMessage
               id="studio.create-service-form.service-pending"
               defaultMessage="Service is not running yet, a preprocessing job is working for the service"
           /></h4>
          }
          {newService && <Link to={toServiceLink(newService)} className="button-link">
            <Button bsStyle="info">
              <span><FormattedMessage id="studio.services.create-service-form.title"
                                      defaultMessage="Go To Service {name}"
                                      values={{name: newService.name}}/></span>
            </Button>
          </Link>}
        </div>
    );
  }

  render() {
    const {submitFailed, submitting, submitSucceeded, handleSubmit, error, intl} = this.props;
    const {showChooseDataRootDialog} = this.state;
    const errorContent = submitFailed ? (
        <Alert bsStyle="danger">
          <strong>
            {intl.formatMessage(SERVICE_FORM_MESSAGES.submitFailed)}
          </strong> {error}
        </Alert>
    ) : null;
    if (submitting) {
      return (
          <div className="form-message">
            <h1>{intl.formatMessage(SERVICE_FORM_MESSAGES.submitting)}</h1>
          </div>
      );
    }
    if (submitSucceeded) {
      return this.confirmationComponent(_newService);
    }

    return (
        <div>
          {errorContent}
          <Form horizontal onSubmit={handleSubmit}>
            <Fields
                names={[FIELD_SERVICE_TITLE, FIELD_SERVICE_TYPE, FIELD_SERVICE_NAME, FIELD_SERVICE_PRODUCTS,
                        FIELD_SERVICE_PREPROCESSING_PATH,
                        "serviceAbstract", "serviceKeywords", "immediatelyStartService"]}
                component={this.renderFields}/>
          </Form>
          <ChooseFolderDialog handleChoose={this.handleChooseDataRoot}
                              show={showChooseDataRootDialog}
                              onHide={this.closeChooseDataRootDialog}
                              onFolderChoosed={this.closeChooseDataRootDialog}
                              initialPath={this._defaultPreprocessingPath}
                              title={intl.formatMessage(SERVICE_FORM_MESSAGES.chooseOutputDirectory)}
                              buttonText={intl.formatMessage(SERVICE_FORM_MESSAGES.chooseLocation)}
          />
        </div>
    );
  }

  private createGeneralWarnings(serviceTypeValidation,
                                serviceTypeInput) {
    const warnings = [];
    if (serviceTypeValidation) {
      serviceTypeValidation.serviceTypes.forEach((serviceType) => {
        if (serviceType.name === serviceTypeInput.value) {
          serviceType.validationMessages.forEach((validationMessage) => {
            if (!validationMessage.product) {
              warnings.push(<Alert bsStyle="danger"><b><FormattedMessage
                  id="studio.create-service-form.general-warning"
                  defaultMessage="WARNING:"/></b>&nbsp;{validationMessage.message}</Alert>);
            }
          });
        }
      });
    }
    return warnings;
  }
}

const submitCreateServiceForm = (values: CreateServiceFormData, dispatch, props: CreateServiceFormProps) => {
  const name = toSafeName(values[FIELD_SERVICE_NAME]);
  const serviceType = values[FIELD_SERVICE_TYPE];
  const preprocessingOutputPath = values[FIELD_SERVICE_PREPROCESSING_PATH];
  const {intl} = props;

  let service: Service = {
    id: null,
    name,
    canDelete: false,
    type: serviceType,
    title: values[FIELD_SERVICE_TITLE],
    abstractText: values.serviceAbstract,
    keywords: values.serviceKeywords.split(",").filter((keyword) => keyword.length !== 0),
  };

  service = Object.assign(service, preprocessingOutputPath && {preprocessingOutputPath});

  let serviceId;
  const products = values[FIELD_SERVICE_PRODUCTS];
  const maybeStartService = () => values.immediatelyStartService && dispatch(actions.startService(serviceId));

  let resolveFunc, rejectFunc;
  const promise = new Promise((resolve, reject) => {
    resolveFunc = resolve;
    rejectFunc = reject;
  });

  dispatch(actions.loadEnabledServiceTypes()).then((serviceConfigurations) => {

    return validateAgainstServiceConfiguration(service, serviceConfigurations, products, intl)
        .then((aService) => dispatch(actions.createService(aService)))
        .then((newService) => {
          serviceId = newService.id;
          _newService = newService;
          // Since API and GUI list products in opposite order, reverse before we send to API.
          return Promise.resolve(
              dispatch(actions.addProductsToService(serviceId, products.map((a) => a.id).reverse())));
        }).then(maybeStartService)
        .then((action) => {
          if (action) {
            _newService = action.payload.service;
          }
          return resolveFunc();
        });
  }).catch((error) => {
    const errorMessage = (error.response && error.response.data &&
                          (error.response.data.message || error.response.data.details) ||
                          error.message);
    rejectFunc(new SubmissionError({_error: errorMessage}));
  });

  return promise;
};
const validateAgainstServiceConfiguration = (service: Service,
                                             serviceConfigurations: ServiceTypeDetails[],
                                             products: Product[],
                                             intl: InjectedIntl) => {
  const serviceConfiguration = serviceConfigurations.find(
      (serviceConf) => serviceConf.serviceType === service.type);
  if (serviceConfiguration) {
    const maxAllowedProductCount = serviceConfiguration.maxAllowedProductCount;
    if (maxAllowedProductCount && maxAllowedProductCount < products.length) {
      return Promise.reject({
        response: {
          data: {
            message: intl.formatMessage(SERVICE_FORM_MESSAGES.productsCountError, {
              type: serviceConfiguration.serviceType.toLocaleUpperCase(),
              count: maxAllowedProductCount,
            }),
          },
        },
      });
    }
    return Promise.resolve(service);
  }

  return Promise.reject({
    response: {
      data: {
        message: intl.formatMessage(SERVICE_FORM_MESSAGES.unknownTypeError, {type: service.type}),
      },
    },
  });
};

const validateCreateServiceForm = (values) => {
  const errors: any = {};
  if (!values[FIELD_SERVICE_TITLE]) {
    errors[FIELD_SERVICE_TITLE] = <FormattedMessage
        id="studio.create-service-form.title-error"
        defaultMessage="Required"
    />;
  }
  if (!values[FIELD_SERVICE_NAME] || values[FIELD_SERVICE_NAME].length === 0) {
    errors[FIELD_SERVICE_NAME] = <FormattedMessage
        id="studio.create-service-form.name-error-required"
        defaultMessage="Required"
    />;
  } else if (toSafeName(values[FIELD_SERVICE_NAME]).length === 0) {
    errors[FIELD_SERVICE_NAME] = <FormattedMessage
        id="studio.create-service-form.name-error-invalid"
        defaultMessage="Given name is not valid. Symbols are not allowed"
    />;
  }
  if (values[FIELD_SERVICE_PRODUCTS] && values[FIELD_SERVICE_TYPE] &&
      !productValidation.areProductsOK(values[FIELD_SERVICE_PRODUCTS], values[FIELD_SERVICE_TYPE])) {
    errors[FIELD_SERVICE_PRODUCTS] = <FormattedMessage
        id="studio.create-service-form.product-error"
        defaultMessage="Some products cannot be used with this service"
    />;
  }
  return errors;
};

export const FORM_NAME = "createServiceForm";
const formConfig = {
  form: FORM_NAME,
  onSubmit: submitCreateServiceForm,
  validate: validateCreateServiceForm,
};

export const CreateServiceFormComponent = CreateServiceFormReactComponent;

export const CreateServiceForm = injectIntl(WithApi(reduxForm<CreateServiceFormData, CreateServiceFormProps>(formConfig)(CreateServiceFormComponent)));

interface CreateServiceFormSubmitButtonProps {
  dispatch: Dispatch<Action>;
  pristine: boolean;
  submitting: boolean;
  submitSucceeded: boolean;
  syncErrors: FormErrors<any>;
}

class CreateServiceFormSubmitButtonComponent extends React.Component
                                                         <CreateServiceFormSubmitButtonProps & InjectedIntlProps, {}> {
  render() {
    const {dispatch, pristine, submitting, submitSucceeded, syncErrors} = this.props;
    if (submitting || submitSucceeded) {
      return null;
    }
    return (
        <Button disabled={pristine || !isEmpty(syncErrors)} onClick={() => dispatch(submit(FORM_NAME))}>
          <FormattedMessage
              id="studio.create-service-form.button"
              defaultMessage="Create Service"
          />
        </Button>
    );
  }
}

export const CreateServiceFormSubmitButton = connect((state) => {
  return {
    pristine: isPristine(FORM_NAME)(state),
    submitting: isSubmitting(FORM_NAME)(state),
    submitSucceeded: hasSubmitSucceeded(FORM_NAME)(state),
    syncErrors: getFormSyncErrors(FORM_NAME)(state),
  };
})(injectIntl(CreateServiceFormSubmitButtonComponent));

export const CloseCreateServiceFormButton = injectIntl(createCloseButton(FORM_NAME));
