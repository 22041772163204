
export interface PreviewMetadata {
  previewable: boolean;
  shouldForceToPreview: boolean;
  georeferenced: boolean;
  empty: boolean;
}

export const DEFAULT_PREVIEW_METADATA: PreviewMetadata = {
  previewable: true,
  shouldForceToPreview: false,
  georeferenced: true,
  empty: false,
};
