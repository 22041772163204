import * as React from "react";
import {ListGroup} from "react-bootstrap";
import * as ReactDOM from "react-dom";
import {Logger} from "../../util/Logger";
import {RenderInBody} from "./RenderInBody";

const SHORT_FORM_SUGGESTION = true;

export interface TypeaheadProps<T> {
  name?: string;
  value: T;
  type?: string;
  placeholder?: string;
  fetchSuggestions: (inputValue: string) => Promise<T[]>;
  renderSuggestion: (item: T, shortForm?: boolean) => string;
  onChange: (newValue: T) => void;
}

export interface TypeaheadState<T> {
  hoverIndex: number;
  requestedSuggestionString: string;
  isRequestingSuggestions: boolean;
  suggestions: T[];
  autoCompleteValue: string;
}

export function Typeahead<T>() {
  return class extends React.Component<TypeaheadProps<T>, TypeaheadState<T>> {

    _logger: Logger = Logger.getLogger("Typeahead");
    _domNode: HTMLDivElement;
    _input: HTMLInputElement;

    constructor(props) {
      super(props);
      this.state = {
        hoverIndex: -1,
        requestedSuggestionString: "",
        isRequestingSuggestions: false,
        suggestions: [],
        autoCompleteValue: null,
      };
    }

    componentDidMount() {
      this._domNode = ReactDOM.findDOMNode(this) as HTMLDivElement;
      window.addEventListener("resize", this.close, true);
      window.addEventListener("scroll", this.close, true);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.close, true);
      window.removeEventListener("scroll", this.close, true);
    }

    putInState = (partialState) => this.setState(Object.assign({}, this.state, partialState));

    handleInputBlur = () => {
      window.removeEventListener("keydown", this.handleWindowKeyPress);
      this.close();
    }

    close = () => {
      this.putInState({ suggestions: []});
    }

    handleInputFocus = () => {
      window.addEventListener("keydown", this.handleWindowKeyPress);
      this.requestSuggestions(this._input.value || "");
    }

    handleWindowKeyPress = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown") {
        this.setHover(this.state.hoverIndex + 1);
        event.stopPropagation();
        event.preventDefault();
      } else if (event.key === "ArrowUp") {
        this.setHover(this.state.hoverIndex - 1);
        event.stopPropagation();
        event.preventDefault();
      } else if (event.key === "Enter") {
        if (this.state.hoverIndex !== -1) {
          this.selectSuggestion(this.state.suggestions[this.state.hoverIndex]);
        } else {
          this.putInState({suggestions: [], hoverIndex: -1});
        }
        event.stopPropagation();
        //LF-1424: Avoid calling preventDefault here! This stops the SearchForm from being submitted when the user presses Enter
      } else if (event.key === "Escape") {
        this.putInState({suggestions: [], hoverIndex: -1});
        event.stopPropagation();
        event.preventDefault();
      }
    }

    handleChange = (event) => {
      //V170-2057: stop click event propagation,
      // so the click event doesn't reach wrapped components (e.g. ControlRoomTable) and focus is lost
      event.preventDefault();
      event.stopPropagation();
      const suggestionQueryString: string = event.currentTarget.value || "";
      const clearedInput = suggestionQueryString.length === 0;
      if (clearedInput) {
        this.requestSuggestions(suggestionQueryString);
        this.putInState({autoCompleteValue: "", suggestions: []});
        this.props.onChange(null);
      } else {
        this.requestSuggestions(suggestionQueryString);
        this.putInState({autoCompleteValue: suggestionQueryString});
      }
    }

    requestSuggestions = (suggestionQueryString: string) => {
      const notShowingSuggestions = this.state.suggestions.length >= 0;
      const queryStringChanged = this.state.requestedSuggestionString !== suggestionQueryString;
      const shouldFetchSuggestions = notShowingSuggestions || queryStringChanged;
      if (shouldFetchSuggestions) {
        this.putInState({isRequestingSuggestions: true, requestedSuggestionString: suggestionQueryString});
        this.props.fetchSuggestions(suggestionQueryString).then((suggestions) => {
          this.putInState({
            hoverIndex: -1,
            suggestions,
            isRequestingSuggestions: false,
          });
        }).catch((error) => {
          this._logger.error("error fetching suggestions:", error);
          this.setState({suggestions: [], isRequestingSuggestions: false, hoverIndex: -1});
        });
      }
    }

    selectSuggestion = (item: T) => {
      this.putInState({
        suggestions: [],
        hoverIndex: -1,
        autoCompleteValue: null,
      });
      this.props.onChange(item);
    }

    setHover = (index: number) => {
      const clampedIndex = Math.max(0, Math.min(this.state.suggestions.length - 1, index));
      const suggestionAtIdx = this.state.suggestions[clampedIndex];
      this.putInState({
        hoverIndex: clampedIndex,
        autoCompleteValue: suggestionAtIdx ? this.renderValue(suggestionAtIdx) : null,
      });
    }

    renderValue = (valueObj) => {
      return valueObj ? this.props.renderSuggestion(valueObj, SHORT_FORM_SUGGESTION) : "";
    }

    renderSuggestions = () => {
      const {suggestions} = this.state;
      if (!Array.isArray(suggestions) || suggestions.length <= 0) {
        return null;
      }
      return (
          <div className={"typeahead-suggestions"}>
            <ListGroup>
              {suggestions.map((suggestion, index) => {
                if (!suggestion) {
                  return null;
                }
                return (
                    <li className={"list-group-item typeahead-suggestion " + (this.state.hoverIndex === index ? "active" : "")}
                        key={index}
                        onMouseDown={() => this.selectSuggestion(suggestion)}>
                      {this.props.renderSuggestion(suggestion)}
                    </li>);
              })}
            </ListGroup>
          </div>
      );
    }

    calculateDomNodeDimensions = () => {
      if (this._domNode) {
        return this._domNode.getBoundingClientRect();
      }
      return {
        bottom: 0,
        left: 0,
        width: 100,
      };
    }

    render() {
      const autoComplete = this.state.autoCompleteValue;
      const propValue = this.props.value;
      //use autoComplete when its defined, otherwise use value from properties
      //also avoid 'null' for the input value, or react will start complaining
      const inputValueString = autoComplete !== null ? autoComplete : this.renderValue(propValue);
      const rect = this.calculateDomNodeDimensions();
      return (
          <div style={{width: "100%"}}>
            <input type="text"
                   className="form-control"
                   onBlur={this.handleInputBlur}
                   onFocus={this.handleInputFocus}
                   onChange={this.handleChange}
                   onClick={this.handleChange}
                   value={inputValueString}
                   name={this.props.name}
                   placeholder={this.props.placeholder}
                   autoComplete="off"
                   ref={(ref) => {this._input = ref; }}
            />
            <RenderInBody top={rect.bottom} left={rect.left} width={rect.width}>
              {this.renderSuggestions()}
            </RenderInBody>
          </div>
      );
    }
  };
}
