import {Handle} from "@luciad/ria/util/Evented";
import {Layer} from "@luciad/ria/view/Layer";
import {LayerTreeNode} from "@luciad/ria/view/LayerTreeNode";
import {LayerTreeVisitor} from "@luciad/ria/view/LayerTreeVisitor";
import {Map} from "@luciad/ria/view/Map";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {Bounds, SpatialModel} from "../../../model";
import {Logger} from "../../util/Logger";
import {Spinner} from "../spinner/Spinner";
import {LayerTree} from "./LayerTree";
import * as riaApi from "./RiaApi";

interface RIAMapProps {
  style: React.CSSProperties;
  wmsUrl?: string;
  dataToVisualize: SpatialModel[];
  shouldFit: boolean;
  shouldAnimateFit: boolean;
  shouldAddGridLayer: boolean;
  shouldHideLayerTree?: boolean;
  wmsReference?: string;
  mapState?: object;
  onMapIdle?: (mapState) => void;
  restrictNavigationBounds?: Bounds;
  onMapInitialized?: (map: Map) => void;
  showPreview: boolean;
}

interface RIAMapState {
  busyLoading: boolean;
}

const waitForSize = (domNode: Element) => {
  return new Promise((resolve) => {
    const checkSize = () => {
      const rect = domNode.getBoundingClientRect();
      if (rect.width > 0 && rect.height > 0) {
        resolve();
      } else {
        setTimeout(checkSize, 10);
      }
    };
    checkSize();
  });
};

export class RIAMap extends React.Component<RIAMapProps, RIAMapState> {

  _logger: Logger = Logger.getLogger("RIAMap");

  mapRef: React.ReactInstance;
  mapNode: Element;
  map: Map;
  mapIdleListener: Handle;
  backgroundLayers: Layer[];

  constructor(props) {
    super(props);
    this.state = {busyLoading: false};
  }

  createMap = (nextProps) => {
    //awkward... The component is mounted before the DOM node is styled and has a height
    this.mapNode = ReactDOM.findDOMNode(this.mapRef) as Element;
    waitForSize(this.mapNode).then(() => {
      this.initMap(nextProps);
    }).catch((error) => {
      this._logger.error("Error occurred while initializing map");
    });
  }

  destroyMap = () => {
    if (this.mapIdleListener) {
      this.mapIdleListener.remove();
    }
    riaApi.destroyMap(this.map);
  }

  clearMap = () => {
    const nonBackgroundLayers = [];
    this.map.layerTree.visitChildren({
      visitLayer: (layer) => {
        if (this.backgroundLayers.indexOf(layer) < 0) {
          nonBackgroundLayers.push(layer);
        }
        return LayerTreeVisitor.ReturnValue.CONTINUE;
      },
      visitLayerGroup: () => {
        return LayerTreeVisitor.ReturnValue.CONTINUE;
      },
    }, LayerTreeNode.VisitOrder.TOP_DOWN);
    nonBackgroundLayers.forEach((nonBackgroundLayer) => this.map.layerTree.removeChild(nonBackgroundLayer));
  }

  initLayers = (props: RIAMapProps) => {
    const {wmsUrl, shouldFit, shouldAnimateFit, dataToVisualize, showPreview} = props;

    let aoiBounds = null;
    if (!showPreview) {
      aoiBounds = riaApi.getBounds(dataToVisualize);
    }
    riaApi.setAreaOfInterest(this.map, aoiBounds);

    const canLoad = !!(wmsUrl && dataToVisualize && dataToVisualize.length && showPreview);
    this.setLoadingState(canLoad);

    if (canLoad) {
      const layerReference = this.map.reference;
      const defaultBounds = this.map.mapBounds;

      const layersLoadedPromises = [];

      // RIA layers should be created in reversed order - last item is painted first
      const reversedData = [...dataToVisualize].reverse();

      reversedData.forEach((dataItem) => {
        const controlRoomBounds = dataItem.wgs84Bounds;
        const riaBounds = controlRoomBounds ? riaApi.createBounds(controlRoomBounds) : defaultBounds;
        const layerName = wmsUrl.substr(wmsUrl.lastIndexOf("/") + 1) + dataItem.id;
        const layerTitle = dataItem.title || dataItem.name;
        const url = wmsUrl + dataItem.id;

        const layer = riaApi.addWMSLayerToMap(this.map, url, layerName, layerTitle, riaBounds, layerReference);
        layersLoadedPromises.push(layer.whenReady());
      });

      Promise.all(layersLoadedPromises).then(() => {
        setTimeout(() => this.setLoadingState(false), 500);
      }).catch((error) => {
        this._logger.error("Error occured while loading layers", error);
      });
    }

    if (shouldFit) {
      const reactorBounds = dataToVisualize.map((dataItem) => {
        return dataItem.wgs84Bounds;
      });
      if (reactorBounds.length > 0) {
        riaApi.fitOnBounds(this.map, reactorBounds, shouldAnimateFit);
      } else {
        const worldBounds = {x: -180, y: -88, width: 360, height: 88 * 2};
        riaApi.fitOnBounds(this.map, [worldBounds], shouldAnimateFit);
      }
    }
  }

  setLoadingState = (state: boolean) => {
    this.setState(Object.assign({}, this.state, {busyLoading: state}));
  }

  initMap = (nextProps) => {
    const props = nextProps ? nextProps : this.props;
    const {wmsReference, shouldAddGridLayer, restrictNavigationBounds} = props;
    this.map = riaApi.createMap(this.mapNode, shouldAddGridLayer, restrictNavigationBounds, wmsReference);
    this.backgroundLayers = [];
    //track background layers, these don't need to be removed upon switching layers
    this.map.layerTree.visitChildren({
      visitLayer: (layer) => {
        this.backgroundLayers.push(layer);
        return LayerTreeVisitor.ReturnValue.CONTINUE;
      },
      visitLayerGroup: () => {
        return LayerTreeVisitor.ReturnValue.CONTINUE;
      },
    }, LayerTreeNode.VisitOrder.TOP_DOWN);

    this.initLayers(props);

    if (this.props.mapState) {
      this.map.restoreState(this.props.mapState).catch((error) => {
        this._logger.error("Error occurred while restoring map state", error);
      });
    }
    if (this.props.onMapIdle) {
      this.mapIdleListener = this.map.on("idle", () => {
        const state = this.map.saveState();
        this.props.onMapIdle(state);
      });
    }
    if (this.props.onMapInitialized) {
      this.props.onMapInitialized(this.map);
    }
  }

  componentDidMount() {
    this.createMap(null);
  }

  componentWillUnmount() {
    this.destroyMap();
  }

  wmsUlrHasChanged(nextProps: RIAMapProps) {
    return (this.props.wmsUrl && (this.props.wmsUrl !== nextProps.wmsUrl));
  }

  dataToVisualizeHasChanged(nextProps: RIAMapProps) {
    const thisData = this.props.dataToVisualize;
    const nextData = nextProps.dataToVisualize;
    if (thisData && nextData) {
      if (thisData.length !== nextData.length) {
        return true;
      }
      let someItemChanged = false;
      for (let i = 0; i < thisData.length; i++) {
        const idChanged = thisData[i].id !== nextData[i].id;
        const sequenceNumberChanged = thisData[i].sequenceNumber !== nextData[i].sequenceNumber;
        // Check if content has changed for styles
        const contentChanged = (thisData[i] as any).content && (nextData[i] as any).content && (thisData[i] as any).content !== (nextData[i] as any).content;
        someItemChanged = someItemChanged || (idChanged || sequenceNumberChanged || contentChanged);
        if (someItemChanged) {
          break;
        }
      }
      return someItemChanged;
    }
    //one of the two is null or undefined
    return nextData === thisData;
  }

  shouldPreviewHasChanged(nextProps) {
    return (this.props.showPreview !== nextProps.showPreview);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.map && (this.wmsUlrHasChanged(nextProps) || this.dataToVisualizeHasChanged(nextProps) ||
                     this.shouldPreviewHasChanged(nextProps))) {
      this.clearMap();
      this.initLayers(nextProps);
    }
  }

  render() {
    const layerTree = this.props.shouldHideLayerTree ? null : <LayerTree map={this.map}/>;
    const loadingUI = this.state.busyLoading ? (<div className="spinnerContainer"><Spinner bigStyle={true}/></div>)
                                             : null;

    return (
        <div className="riamap" ref={(ref) => {
          this.mapRef = ref;
        }} style={this.props.style}>
          {loadingUI}
          {layerTree}
        </div>
    );
  }
}
