import {ConfigurationInfo} from "../model";
import {StartupState} from "./reducer";

export const moduleName = "startup";

const getStartupState = (state) => state[moduleName] as StartupState;

const getConfigurationInfo = (state): ConfigurationInfo => {
  return getStartupState(state).configurationInfo;
};

const isMappEnterprise = (state): boolean => {
  const config = getConfigurationInfo(state);
  return config && config.securityInfo && config.securityInfo.isMappEnterprise;
};

export const selectors = {
  getConfigurationInfo,
  isMappEnterprise,
};
